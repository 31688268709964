import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function Road_map() {
    return (
        <VerticalTimeline style={{ backgroundcolor: "#046307" }}>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                date="Current Ongoing Phase"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                
            >
                <h3 className="vertical-timeline-element-title">Launch Phase</h3>
                <h4 className="vertical-timeline-element-subtitle"></h4>
                <p>
                - Sell out collection
                , Community Events
                , Daily Giveaways
                , Airdrops
                , whitelisting
                , Art contests 
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="Drop 2 - Post Launch"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                
            >
                <h3 className="vertical-timeline-element-title">Post-Launch</h3>
                <h4 className="vertical-timeline-element-subtitle">For the community ♥</h4>
                <p>
                - 25% to charity
                , 0.3 ETH for community
                , 2nd Drop of TallNecks
                , Merch Launch
                , 3D Avatars
                , Real Life Events
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="Holder Benefits"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                
            >
                <h3 className="vertical-timeline-element-title">Working On Holder Benefits</h3>
                <h4 className="vertical-timeline-element-subtitle"></h4>
                <p>
                - $TallNecks Token
                , Each holder gets 5 Tokens for free
                , Partnerships
                , P2E Mobile Game for $TallNecks Token!
                </p>
            </VerticalTimelineElement>
        </VerticalTimeline>
    )
}

export default Road_map;
