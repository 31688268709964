import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Road_map from "./styles/Road_map.js";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;


function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <Scrollbars style={{ height: 800 }}>
    <s.Screen>
      <s.Container>
        <s.SpacerXSmall />
        <s.ResponsiveWrapper>
          <s.SpacerLarge />
          <s.StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        </s.ResponsiveWrapper>
        <s.ResponsiveWrapper flex={1} style={{ padding: 80 }}>
          <s.SpacerXLarge />
          <s.SpacerLarge />
          <s.Container flex={1}>
            <s.SpacerLarge />
            <s.TextTitle>TallNecks</s.TextTitle>
            <s.SpacerSmall />
            <s.TextSubTitle>TallNecks is a collection of cool NFTs which feature Doodle Art with TallNecks!!! TallNecks is a community-driven profile picture project featuring art by Ayden and Lypheal.</s.TextSubTitle>
            <s.ResponsiveWrapper>
             <s.StyledButton
                            onClick={(e) => {
                               window.open(CONFIG.MARKETPLACE_LINK, "_blank");
                             }}
             >Opensea</s.StyledButton>
             <s.SpacerXSmall />
             <s.StyledButton
                onClick={(e) => {
                  window.open("https://polyrare.com/tallnecks/", "_blank");
                }}>Check Rarity</s.StyledButton>
             <s.SpacerXSmall />
             <s.StyledButton
                onClick={(e) => {
                  window.open("https://stake.tallnecks.com/", "_self");
                }}>Stake Your TallNecks</s.StyledButton>
            </s.ResponsiveWrapper>      
          </s.Container>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <s.StyledImg alt={"example"} src={"/config/images/example.png"} />
          </s.Container>
        </s.ResponsiveWrapper> 
      </s.Container>
      <s.ResponsiveWrapper style={{ padding: 160, backgroundColor: "#046307" }}>
        <s.Container flex={1} jc={"center"} ai={"center"}>
          <s.StyledImg alt={"example"} src={"/config/images/example.gif"} />
        </s.Container>
        <s.Container flex={1}>
          <s.SpacerMedium />
          <s.TextTitle>TallNecks NFT</s.TextTitle>
          <s.TextDescription>TNNFT is a collection of 3,333 (More to come in Drop 2) TallNecks NFTs—unique digital collectibles living on the Polygon blockchain.</s.TextDescription>
          <s.TextDescription>Future areas and perks can be unlocked by the community through roadmap activation.</s.TextDescription>
          <s.TextTitle>FAIR DISTRIBUTION</s.TextTitle>
          <s.TextDescription>There are no bonding curves here. Buying a TallNecks costs 1 MATIC. Drop 2 Coming soon! Join our Discord for updates on Whitelist!</s.TextDescription>
        </s.Container>  
      </s.ResponsiveWrapper>
      <s.Container
        flex={1}
        jc={"center"}
        ai={"center"}
        style={{ padding: 100 }}
      >     
        <s.Container
          flex={2}
          jc={"center"}
          ai={"center"}
          style={{
            padding: 24,
            boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            width: "50%",
            backgroundColor: "#FDD017",
            position: "absolute",
            top: 1150,
          }}
        >
          <s.TextTitle style={{ color: "black" }}>
            {data.totalSupply} / {CONFIG.MAX_SUPPLY}
          </s.TextTitle>
          <s.TextDescription style={{ color: "var(--primary-text)" }}>
            <s.StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
              {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
            </s.StyledLink>
          </s.TextDescription>
          {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
            <>
              <s.TextTitle style={{ color: "black" }}>The sale has ended.</s.TextTitle>
              <s.TextDescription style={{ color: "black" }}>
                You can still find {CONFIG.NFT_NAME} on
              </s.TextDescription>
              <s.StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                {CONFIG.MARKETPLACE}
              </s.StyledLink>
            </>
          ) : (
            <>
              <s.TextTitle style={{ color: "black" }}>
                1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                {CONFIG.NETWORK.SYMBOL}.
              </s.TextTitle>
              <s.TextDescription style={{ color: "black" }}>
                Excluding gas fees.
              </s.TextDescription>
              {blockchain.account === "" ||
              blockchain.smartContract === null ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.TextDescription style={{ color: "black" }}>
                    Connect to the {CONFIG.NETWORK.NAME} network
                  </s.TextDescription>
                  <s.StyledButton
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getData();
                    }}
                  >
                    CONNECT
                  </s.StyledButton>
                  {blockchain.errorMsg !== "" ? (
                    <>
                      <s.TextDescription style={{ color: "black" }}>
                        {blockchain.errorMsg}
                      </s.TextDescription>
                    </>
                  ) : null}
                </s.Container>
              ) : (
                <>
                  <s.TextDescription style={{ color: "black" }}>
                    {feedback}
                  </s.TextDescription>
                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <s.StyledRoundButton
                      style={{ lineHeight: 0.4 }}
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        decrementMintAmount();
                      }}
                    >
                      -
                    </s.StyledRoundButton>
                    <s.TextDescription style={{ color: "black" }}>
                      {mintAmount}
                    </s.TextDescription>
                    <s.StyledRoundButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        incrementMintAmount();
                      }}
                    >
                      +
                    </s.StyledRoundButton>
                  </s.Container>
                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <s.StyledButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs();
                        getData();
                      }}
                    >
                      {claimingNft ? "BUSY" : "BUY"}
                    </s.StyledButton>
                  </s.Container>
                </>
              )}
            </>
          )}
        </s.Container>     
        <s.SpacerXLarge />
        <s.Container jc={"center"} ai={"center"} style={{ width: "60%" }}>
          <s.TextSubTitle>
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextSubTitle>
          <s.SpacerSmall />
          <s.TextSubTitle>
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextSubTitle>
        </s.Container>
      </s.Container>
      <s.Container jc={"center"} ai={"center"} style={{ backgroundColor: "#046307" }}>
        <s.SpacerSmall />
        <s.TextSubTitle>TallNecks</s.TextSubTitle>
        <s.TextTitle>R O A D M A P</s.TextTitle>
        <s.SpacerXSmall />
        <Road_map />
        <s.SpacerMedium />      
      </s.Container>  
    </s.Screen>
    </Scrollbars>
  );
}

export default App;
